import React, { useEffect, useState } from 'react'; // Import React, useState, and useEffect
import styled from 'styled-components';
import { FaArrowUp } from 'react-icons/fa';

const GoToTop = () => { // Rename function to start with an uppercase letter
  const [isVisible, setIsVisible] = useState(false);

  const goToBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const listenToScroll = () => {
    let heightToHidden = 250;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []); // Empty dependency array to run only once on mount and cleanup on unmount

  return (
    <Wrapper>
      {isVisible && (
        <div className='top-btn' onClick={goToBtn}>
          <FaArrowUp className='top-btn--icon' />
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  .top-btn {
    font-size: 2rem;
    width: 4rem;
    height: 4rem;
    color: red;
//     background-color: red;
    border-radius: 50%;
    position: fixed;
    bottom: 1rem;
    right: 6rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;

    &--icon {
      animation: gototop 1.2s linear infinite alternate-reverse;
    }
    @keyframes gototop {
      0% {
        transform: translateY(-0.5rem);
      }
      100% {
        transform: translateY(1rem);
      }
    }
  }
`;

export default GoToTop; // Export with the corrected name
