import React from 'react';
import Header from "./Header";
import TrustedBrand from "../src/TrustedBrand";
import ProductDetails from "../src/ProductDetails";
import CustomerExperience from "./CustomerExperience";

import YearsOfTrust from "../src/YearsOfTrust";

import { Link } from "react-router-dom";

const Home = () => {
  return (
      <>
      <div className='app'>
      <Link to="/">
      <Header />
      <ProductDetails />
      <CustomerExperience />
      {/* <Services/> */}
      <YearsOfTrust/>
      <TrustedBrand />
      </Link>
      </div>
      </>
  )
};

export default Home