import React from "react";
import "./MyTeam.css";
import Manjhusha from '../src/Images/Senior.jpg'
import Nishaa from '../src/Images/NishaMam1.jpg'
import profile from '../src/Images/profile.png'
import AOS from "aos";
import "aos/dist/aos.css"
import { useEffect } from 'react';

function MyTeam() {
    useEffect(() => {
    AOS.init({duration:2000})
  },[])
  return (
    <div>
      
      <section style={{padding:"0",width:"100%",maxWidth:"100%"}}>
          <div className="newTeamDiv1" data-aos="zoom-in">
            <div className="newTeamHeading1" data-aos="zoom-in">
              <h1 data-aos="zoom-in" style={{fontWeight:"bold",fontSize:"35px",}}>LEADERSHIP TEAM</h1>
            </div>
            <div  data-aos="zoom-in" className="newteamManDiv">
              <div className="newTeamSubDiv" data-aos="zoom-in">
                <img
                  className="newTeamImage"
                  src={Nishaa}
                  alt=""
                />
                <div className="newTeamheadingdiv" data-aos="zoom-in">
                  <h2 style={{fontSize:"30px"}} data-aos="zoom-in">Nisha Singh</h2>
                  <h3 style={{fontSize:"20px",color:"red"}} data-aos="zoom-in">HR professional & Operations Lead</h3>
                  <br />
                </div>
                <div className="infoAboutDirectors" data-aos="zoom-in">
                    <p>
                    As a HR professional and Operations Lead with a technical background, I bring a unique blend of analytical skills and people-centric approach to talent management. With five years of experience in talent acquisition, 
                    I've honed the ability to identify and attract top-tier candidates across diverse industries. My technical acumen enables me to understand the specific skill sets required for various roles, facilitating efficient recruitment processes.
                    I thrive in fast-paced environments, leveraging both my technical expertise and HR insights to streamline hiring procedures and foster a culture of innovation. Committed to continuous learning, I stay updated on industry trends 
                    to ensure effective talent acquisition strategies that drive organizational success
                    </p>
                </div>
              </div>
              <div className="newTeamSubDiv" data-aos="zoom-in">
                <img
                  className="newTeamImage"
                  src={Manjhusha}
                  alt=""
                />
                <div className="newTeamheadingdiv" data-aos="zoom-in">
                  <h2 style={{fontSize:"30px"}} data-aos="zoom-in">Sarang Balankhe</h2>
                  <h3 style={{fontSize:"20px",color:"red"}} data-aos="zoom-in">Result-oriented Senior Management Lead</h3>
                  <br />
                </div>
                <p className="infoAboutDirectors" data-aos="zoom-in">
                Results-oriented Senior Management leader proven success over 30 years in Telecom Industry (Both at Corporate and Circle level).
                    Highly proven track record of working with business heads for cohesive working for seamless Technology Function Delivery.
                    Good exposure of international Market as a part of telecom evolution in India in terms of coordination with major international 
                    vendors and telecom operator.Proficiencies in Project Management, 
                    System Implementation, Team Building and Team Development.
                </p>
              </div>
            </div>
          </div>  



<div className="newTeamDiv1" data-aos="zoom-in">
           
            <div  data-aos="zoom-in" className="newteamManDiv">
              <div className="newTeamSubDiv" data-aos="zoom-in">
                <img
                  className="newTeamImage"
                  src={profile}
                  alt=""
                />
                <div className="newTeamheadingdiv" data-aos="zoom-in">
                  <h2 style={{fontSize:"30px"}} data-aos="zoom-in">Amol Sonawane</h2>
                  <h3 style={{fontSize:"20px",color:"red"}} data-aos="zoom-in">Dedicated and visionary Art Director</h3>
                  <br />
                </div>
                <div className="infoAboutDirectors" data-aos="zoom-in">
                    <p>
                    Dedicated and visionary Art Director with over 15 years of rich experience in leading creative teams and delivering exceptional visual solutions across various industries.
                    Adept at combining artistic talent with strategic thinking to conceptualize and execute compelling designs that align with brand objectives. Proven track record of driving projects from inception to completion while exceeding client expectations. Passionate about fostering collaboration and mentoring team members to achieve excellence.

                    Worked for many Feature films, Addfilms, Webseries, Branding design and development for many Reputed companies.


                    </p>
                </div>
              </div>
              <div className="newTeamSubDiv" data-aos="zoom-in">
                <img
                  className="newTeamImage"
                  src={profile}
                  alt=""
                />
                <div className="newTeamheadingdiv" data-aos="zoom-in">
                  <h2 style={{fontSize:"30px"}} data-aos="zoom-in">Mohmmad Ubaid</h2>
                  <h3 style={{fontSize:"20px",color:"red"}} data-aos="zoom-in">IT Project Manager</h3>
                  <br />
                </div>
                <p className="infoAboutDirectors" data-aos="zoom-in">
                 Experienced IT project manager with six years in the industry, adept at leading cross-functional teams and delivering successful projects. Proven track record in IT professional roles, demonstrating strong project management skills and a results-driven approach.
                </p>
              </div>
            </div>
          </div> 

      
   </section>
    </div>
  );
}

export default MyTeam;