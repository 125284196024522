import React, { useState } from "react";
import axios from "axios";
import "./chatBot.css";
import chatBot from "./Images/chatbot.png";

function ChatBot() {
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [generatingAnswer, setGeneratingAnswer] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(300); // Initial width of the chatbot popup

  async function generateAnswer(e) {
    e.preventDefault();
    setGeneratingAnswer(true);
    try {
      const response = await axios.post(
        "https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=AIzaSyDashTBBfWgv41WGd6mrZ4RjiH6AkoEapY",
        {
          contents: [{ parts: [{ text: question }] }],
        }
      );

      // Append both question and answer to messages
      setMessages([
        ...messages,
        { text: question, sender: "You" },
        { text: response.data.candidates[0].content.parts[0].text, sender: "Domla" }
      ]);
    } catch (error) {
      console.log(error);
      setMessages([
        ...messages,
        { text: "Sorry, something went wrong. Please try again!", sender: "Domla" }
      ]);
    }
    setGeneratingAnswer(false);
    setQuestion(""); // Clear the input box
  }

  function handleResize(event) {
    setWidth(event.clientX - event.target.getBoundingClientRect().left);
  }

  return (
    <>
      <div className="chatbot-container">
        <div className="chatbot-button" onClick={() => setIsOpen(!isOpen)}>
          <img src={chatBot} alt="Chatbot Icon" />
        </div>
      </div>
      <div
        className={`chatbot-popup ${isOpen ? 'open' : ''}`}
        style={{ width: `${width}px` }}
      >
        <div className="chatbot-header">
          <h2>Ask Domla</h2>
          <button className="close-btn" onClick={() => setIsOpen(false)}>Close</button>
        </div>
        <div className="chatbot-body">
          <div className="chatbot-messages">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.sender === "You" ? "user" : "bot"}`}>
                <p><span className="sender">{message.sender}: </span>{message.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="chatbot-footer">
          <form onSubmit={generateAnswer}>
            <textarea
              required
              className="question-input"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Ask anything"
            ></textarea>
            <button
              type="submit"
              className="generate-answer-btn"
              disabled={generatingAnswer}
            >
              {generatingAnswer ? "Formulating..." : "Solutions"}
            </button>
          </form>
        </div>
        <div
          className="resize-handle"
          onMouseDown={(e) => {
            document.addEventListener('mousemove', handleResize);
            document.addEventListener('mouseup', () => {
              document.removeEventListener('mousemove', handleResize);
            });
          }}
        ></div> {/* Resize handle */}
      </div>
    </>
  );
}

export default ChatBot;
