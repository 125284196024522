import React from 'react';
import './Consultancy.css';
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import digital from "../src/Images/digital.jpg";
import customerOnBoarding from "../src/Images/onBoarding.jpg";
import voice from "../src/Images/voice.jpg";
import recruitment from "../src/Images/recruitment.jpg";
import bpo from "../src/Images/bpo.jpg";
import dataMan from "../src/Images/dataMan.jpg"

const Consultancy = () => {
      useEffect(() => {
    AOS.init({duration:2000})
  },[])
  return (
//     <div>
//     <section style={{width:"100%",maxWidth:"100%"}}>
//       <div>
//         <div className='ConsulatncymainDiv' data-aos="fade-right">
//          <center data-aos="fade-right"><h1 className='industries-we-serve-heading'>OUTSOURCING SERVICES</h1></center>
//           <div className='ConsulatncysubDiv' data-aos="fade-right">
//             <div className='ConsulatncyimgSection' data-aos="fade-right">
//               <img className='ConsulatncyimgDiv' style={{width:"45%",maxWidth:"100%"}} src={digital} alt="" />
//             </div>
//             <div className='ConsulatncyparaDiv' data-aos="fade-right">
//               <h2 className='Consulatncyheading2'>Digitization & Document Management</h2>
//               <p className="justify" >
//                157 Document Management System provides online access for storing, managing, and tracking electronic documents. Multiple users at the client's end can access the same document from anywhere. Imperative takes a proactive approach to mitigate losses from natural calamities or disasters by converting documents into digital formats such as PDF, TIFF, PNG, JPEG, etc. Digitization simplifies the documentation process, making it easier to store, search, access, retrieve, and send documents as needed.
//               </p>
//             </div>
//           </div>

//           <div className='ConsulatncysubDiv' data-aos="fade-right">
//             <div className='ConsulatncyparaDiv' data-aos="fade-right">
//               <h2 className='Consulatncyheading2'>Customer Onboarding Forms Processing</h2>
//               <p className="justify" >
//                 157 offers automated customer onboarding form processing, enabling banks to streamline the onboarding process for new customers and ensuring efficiency and accuracy in the Know Your Customer (KYC) process. We conduct thorough customer identification checks to uncover historical company records and sanctions against a customer. Through Imperative's web applications, banks can monitor their customers and receive notifications of any changes to their financial or ownership information, ensuring awareness of potential new risks
//               </p>
//             </div>
//             <div className='ConsulatncyimgSection' data-aos="fade-right">
//               <img className='ConsulatncyimgDiv' style={{width:"45%",maxWidth:"100%"}} src={ customerOnBoarding} alt="" />
//             </div>
//           </div>

//           <div className='ConsulatncysubDiv' data-aos="fade-right">
//             <div className='ConsulatncyimgSection' data-aos="fade-right">
//               <img className='ConsulatncyimgDiv' style={{width:"45%",maxWidth:"100%"}} src={voice} alt="" />
//             </div>
//             <div className='ConsulatncyparaDiv' data-aos="fade-right">
//               <h2 className='Consulatncyheading2'>Voice-Based Processes</h2>
//               <p className="justify" >
//                 157 Industries has developed a comprehensive range of both inbound and outbound services and solutions to address our clients' business needs and drive successful outcomes.

// Voice-Based Processes involve handling calls from customers or prospects seeking information about a product or service or clarification of queries.

// Recognizing the importance of this, at Imperative, we have crafted effective solutions tailored to our clients' business needs.
//               </p>
//             </div>
//           </div>

//           <div className='ConsulatncysubDiv' data-aos="fade-right">
//             <div className='ConsulatncyparaDiv' data-aos="fade-right">
//               <h2 className='Consulatncyheading2'>Recruitment Process Outsourcing (RPO)</h2>
//               <p className="justify" >
//               Recruitment Process Outsourcing (RPO) is a form of business process outsourcing where an employer delegates some or all of its recruitment tasks to an external service provider, aiming to achieve benefits in terms of cost, quality, efficiency, service, and scalability.

// 157's RPO process is designed to enhance hiring efficiency and retention while ensuring consistency and compliance in the recruitment and selection stages. It encompasses five key stages: planning, strategy development, searching, screening, and evaluating. This comprehensive approach is tailored to attract a diverse and talented pool of applicants.
//               </p>
//             </div>
//             <div className='ConsulatncyimgSection' data-aos="fade-right">
//               <img className='ConsulatncyimgDiv' style={{width:"600px",maxWidth:"100%"}} src={recruitment} alt="" />
//             </div>
//           </div>

//           <div className='ConsulatncysubDiv' data-aos="fade-right">
//             <div className='ConsulatncyimgSection' data-aos="fade-right">
//               <img className='ConsulatncyimgDiv' style={{width:"45%",maxWidth:"100%"}} src={bpo} alt="" />
//             </div>
//             <div className='ConsulatncyparaDiv' data-aos="fade-right">
//               <h2 className='Consulatncyheading2' data-aos="fade-right">BPO / KPO Services</h2>
//               <p className="justify" >
//                 157 delivers cost-effective BPO/KPO services across diverse sectors, including banking, finance, insurance, retail, communication, healthcare, media & entertainment, travel & hospitality, education, and more. We support organizations by providing high-quality BPO/KPO services infused with advanced analytical and technical skills.
//               </p>
//             </div>
//           </div>

//           <div className='ConsulatncysubDiv' data-aos="fade-right">
//             <div className='ConsulatncyparaDiv' data-aos="fade-right">
//               <h2 className='Consulatncyheading2' data-aos="fade-right">Data Management</h2>
//               <p className="justify" >
//                 157 provides comprehensive data management services, securely storing data following client guidelines. Regular data management and audits are conducted based on client preferences. Data analysis is visually presented using graphs and charts with different categories, enhancing clarity on trends. This visual approach surpasses traditional tables, aiding informed decision-making.
//               </p>
//             </div>
//             <div className='ConsulatncyimgSection' data-aos="fade-right">
//               <img className='ConsulatncyimgDiv' style={{width:"45%",maxWidth:"100%"}} src={dataMan} alt="" />
//             </div>
//           </div>

//         </div>
//       </div>
//     </section>
//     </div>


<div>
<section className="Consulting-mainSection">
        <h1 className="ConsultingMainH1">App  Development</h1>
        <div className="Consulting-mainSection-subDiv" data-aos="fade-left">
            {/* <div className="ConsultingImageDiv" data-aos="fade-left">
                <img className="ConsultingImg" src={digital} alt=''></img>
            </div> */}
            <div className="ConsultingParaDiv" data-aos="fade-left">
                <h1 className="SubDivHeading">iOS App Development:</h1>
                <p className="ConsultingPara"><span>Description:</span> Our expertise lies in crafting tailor-made iOS applications designed to meet your specific requirements and preferences.</p>
                <p className="ConsultingPara"><span>Services Offered:</span>
                Native iOS app development using Swift and Objective-C, ensuring top-notch performance and seamless integration with the Apple ecosystem.
                Exceptional UI/UX design focused on delivering an intuitive and delightful user experience, fostering engagement and loyalty.
                Integration of iOS apps with backend services and APIs, ensuring robust functionality and real-time data synchronization.
                Rigorous app testing, debugging, and optimization to ensure stellar performance, stability, and compatibility across all iOS devices.</p>
                <p className="ConsultingPara"><span>Benefits:</span>
                Reach a vast audience of iPhone and iPad users, capitalizing on the popularity of the iOS platform.Leverage the latest features and capabilities of iOS devices to provide users with cutting-edge experiences.Ensure compliance with Apple's strict App Store guidelines, establishing trust and credibility among users and stakeholders.</p>

            </div>
        </div>

        <div className="Consulting-mainSection-subDiv" data-aos="fade-left">    
            <div className="ConsultingParaDiv" data-aos="fade-left">
                <h1 className="SubDivHeading">Android App Development:</h1>
                <p className="ConsultingPara"><span>Description:</span> Our seasoned team specializes in developing custom Android applications known for their user-friendliness, scalability, and feature-rich nature.
                </p>
                <p className="ConsultingPara"><span>Services Offered:</span>
                Expertise in native Android app development using Java and Kotlin, delivering high-performance applications tailored to the Android ecosystem.
                Cross-platform app development using leading frameworks such as React Native or Flutter, ensuring consistency and efficiency across multiple platforms.
                Seamless integration of Android apps with cloud services and third-party APIs, enabling enhanced functionality and data accessibility.
                Ongoing support and maintenance post-launch to ensure optimal performance and responsiveness.
                </p>
                <p className="ConsultingPara"><span>Benefits:</span>
                Tap into the expansive Android user base worldwide, maximizing your app's reach and potential market share.
                Deliver seamless experiences across diverse Android devices and screen sizes, catering to the preferences and needs of a broad audience.
                Stay updated with the latest trends and advancements in the dynamic Android ecosystem.
                </p>
                
            </div>
            {/* <div className="ConsultingImageDiv" data-aos="fade-left">
                <img className="ConsultingImg" src={customerOnBoarding}></img>
            </div> */}
        </div>

        <div className="Consulting-mainSection-subDiv" data-aos="fade-left">
            {/* <div className="ConsultingImageDiv" data-aos="fade-left">
                <img className="ConsultingImg" src={voice}></img>
            </div> */}
            <div className="ConsultingParaDiv" data-aos="fade-left">
                <h1 className="SubDivHeading">Cross-Platform App Development:</h1>
                <p className="ConsultingPara"><span>Description:</span> Our cross-platform app development solutions enable you to efficiently target both iOS and Android users with a unified codebase.</p>
                <p className="ConsultingPara"><span>Services Offered:</span>
                Leveraging frameworks like React Native, Flutter, or Xamarin for cross-platform app development, reducing time-to-market and streamlining development efforts.
                Code sharing across multiple platforms to minimize duplication of effort, ensuring consistent functionality and user experience.
                Tailored native performance and UI/UX experiences for each platform, delivering seamless and intuitive experiences for users.
                Continuous integration and testing practices to maintain quality and reliability across various platforms and device configurations.</p>
                <p className="ConsultingPara"><span>Benefits:</span>
                Accelerate time-to-market by deploying apps simultaneously on multiple platforms, maximizing exposure and market penetration.
                Cost-effective development through shared codebase and resources, optimizing development efforts and resource allocation.
                Ensure long-term maintainability and scalability with unified updates and enhancements.</p>
            </div>
        </div>

        

        <div className="Consulting-mainSection-subDiv" data-aos="fade-left">
            {/* <div className="ConsultingImageDiv" data-aos="fade-left">
                <img className="ConsultingImg" src={digital} alt=''></img>
            </div> */}
            <div className="ConsultingParaDiv" data-aos="fade-left">
                <h1 className="SubDivHeading">App Design and Consulting:</h1>
                <p className="ConsultingPara"><span>Description:</span> Our team collaborates closely with you to conceptualize, design, and strategize your app development projects.</p>
                <p className="ConsultingPara">
                   <span> Services Offered:</span>
                Crafting engaging and intuitive user experiences through meticulous UX/UI design, captivating and retaining users.
                Visualizing app concepts through wireframing, prototyping, and mockup creation, facilitating stakeholder alignment.
                Requirements gathering and project scoping to define app functionality and features, laying the groundwork for successful development.
                Strategic consulting on app monetization strategies, market research, and competitor analysis, maximizing app success.
                </p>
                <p className="ConsultingPara">
                   <span> Benefits:</span>
                Create compelling and memorable app interfaces that resonate with users, fostering positive perceptions and brand loyalty.
                Validate app ideas through iterative user feedback and testing, ensuring alignment with user expectations and market demands.
                Align app development initiatives with business objectives, optimizing return on investment and driving sustainable growth.
                </p>
            </div>
        </div>

        {/* <div className="Consulting-mainSection-subDiv" data-aos="fade-left">
            <div className="ConsultingParaDiv" data-aos="fade-left">
                <h1 className="SubDivHeading">Data Management</h1>
                <p className="ConsultingPara">157 provides comprehensive data management services, securely storing data following client guidelines. Regular data management and audits are conducted based on client preferences. Data analysis is visually presented using graphs and charts with different categories, enhancing clarity on trends. This visual approach surpasses traditional tables, aiding informed decision-making.</p>
            </div>
            <div className="ConsultingImageDiv" data-aos="fade-left">
                <img className="ConsultingImg" src={customerOnBoarding}></img>
            </div>
        </div> */}

        
</section>
</div>  
  );
};

export default Consultancy;