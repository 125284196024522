import React, { useState } from 'react';
import './searchBar.css'; // Import CSS for styling

const SearchBar = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setIsSearchOpen(false); // Close the search input box
    }
  };

  return (
    <div className="search-bar">
      <div className={`search ${isSearchOpen ? 'open' : ''}`}>
        <input
          className='search-input'
          type="text"
          placeholder="Search here"
          onKeyPress={handleKeyPress} // Listen for Enter key press
        />
      </div>
      <i className={`fa-solid fa-magnifying-glass ${isSearchOpen ? 'hidden' : ''}`} onClick={toggleSearch}></i>
    </div>
  );
};

export default SearchBar;
