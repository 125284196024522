import React from 'react';
import './Consultancy.css';
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import Market from "../src/Images/Market&Research.jpg"
import mb from "../src/Images/MarB.jpg"
import heath from "../src/Images/Health.jpg"
import softwearCon from "../src/Images/SoftwearCon.jpg"

const Consultancy = () => {
      useEffect(() => {
    AOS.init({duration:2000})
  },[])
  return (
    // <div>
    // <section style={{width:"100%",maxWidth:"100%"}}>
    //   <div>
    //     <div className='ConsulatncymainDiv'data-aos="fade-right">
    //      <center data-aos="fade-right" ><h1 style={{color:'red',fontSize:"3rem",fontWeight:"bold"}}>CONSULTANCY SERVICES</h1></center>
    //       <div className='ConsulatncysubDiv'  data-aos="fade-right">
    //         <div className='ConsulatncyimgSection' data-aos="fade-right">
    //           <img className='ConsulatncyimgDiv'  src={Market} alt="" />
    //         </div>
    //         <div className='ConsulatncyparaDiv' data-aos="fade-right" >
    //           <h2 className='Consulatncyheading2'>Market Research & Analysis</h2>
    //           <p className='ConsulatncyParagraph1 justify'>
    //             Market research is essential for shaping your marketing strategy. When done effectively, it illuminates key aspects of your marketing efforts, like understanding your target audience's needs, aiding customer problem-solving, and refining your approach. 157 helps you assess the size of your new addressable market without costly and time-consuming efforts. With a proven track record in researching new markets, our solutions are customized for you."
    //           </p>
    //         </div>
    //       </div>

    //       <div className='ConsulatncysubDiv' data-aos="fade-right">
    //         <div className='ConsulatncyparaDiv' data-aos="fade-right">
    //           <h2 className='Consulatncyheading2'>Marketing and Business Development</h2>
    //           <p className="justify" >
    //             At 157 Industries, we specialize in delivering Digital Marketing and Commerce services, offering clients result-driven experiences. Our comprehensive approach involves in-depth analysis of current market strategies, trends, and customer data and insights, empowering organizations to confidently achieve new milestones.
    //           </p>
    //         </div>
    //         <div className='ConsulatncyimgSection' data-aos="fade-right">
    //           <img className='ConsulatncyimgDiv'  src={mb} alt="" />
    //         </div>
    //       </div>

    //       <div className='ConsulatncysubDiv' data-aos="fade-right">
    //         <div className='ConsulatncyimgSection' data-aos="fade-right">
    //           <img className='ConsulatncyimgDiv'  src={heath} alt="" />
    //         </div>
    //         <div className='ConsulatncyparaDiv' data-aos="fade-right">
    //           <h2 className='Consulatncyheading2'>Health Care Solutions</h2>
    //           <p className="justify" >
    //             At 157 Industries, our AI-driven Healthcare Solutions uplift hospital and clinic operations, introducing automated workflows and paperless processes for enhanced efficiency.Our solutions bridge gaps between patients, nurses, and doctors, streamlining hospital operations for a higher level of service quality. Empowering healthcare professionals to make quick decisions results in faster disease recovery. Our offerings include Telehealthcare, Hospital Management System, and Telemedicine Solutions.
    //           </p>
    //         </div>
    //       </div>

    //       <div className='ConsulatncysubDiv' data-aos="fade-right" style={{width:"100%",maxWidth:"100%"}}>
    //         <div className='ConsulatncyparaDiv' data-aos="fade-right">
    //           <h2 className='Consulatncyheading2'>Software Consulting & Sales</h2>
    //           <p className="justify" >
    //            In the dynamic landscape of business, software consulting services play a crucial role in evaluating and improving the efficiency of technology-enabled Business Process Management (BPM). Today, businesses across various industries thrive on new technologies, AI-based analytics, streamlined processes, and effective customer relationship management.At 157, we provide industry-specific software consulting solutions crafted to meet the unique business needs of sectors such as BFSI, NBFC, Healthcare, Telecom, Retail, Logistics, Hospitality, Media and Entertainment, and Corporates in India and Overseas.
    //           </p>
    //         </div>
    //         <div className='ConsulatncyimgSection' data-aos="fade-right">
    //           <img className='ConsulatncyimgDiv'   src={softwearCon} alt="" />
    //         </div>
    //       </div>

    //     </div>
    //   </div>
    // </section>
    // </div>


    <div>
            <section className="Consulting-mainSection">
                    <h1 className="ConsultingMainH1">Software Development</h1>
                    <div className="Consulting-mainSection-subDiv" data-aos="fade-right">
                        {/* <div className="ConsultingImageDiv" data-aos="fade-right">
                            <img className="ConsultingImg" src={Market} alt=''></img>
                        </div> */}
                        <div className="ConsultingParaDiv" data-aos="fade-right">
                            <h1 className="SubDivHeading"> Consultation and Requirements Gathering</h1>
                            <p className="ConsultingPara"><span>Understanding Your Needs:</span> At 157 Careers Pvt Ltd, we begin with comprehensive consultations to understand your business goals and software requirements deeply.
                            </p>
                            <p className="ConsultingPara"><span>Requirements Analysis:</span> Our experienced team conducts meticulous analysis to capture detailed specifications and functionalities crucial for your software solution's success.</p>
                           <p className="ConsultingPara">
                            <span>Planning and Strategy:</span> We create a tailored plan and strategy to precisely meet your unique needs and objectives, ensuring optimal outcomes for your business.
                           </p>
                        </div> 
                    </div>

                    <div className="Consulting-mainSection-subDiv" data-aos="fade-right">
                        <div className="ConsultingParaDiv" data-aos="fade-right">
                            <h1 className="SubDivHeading">Custom Software Design and Development</h1>
                            <p className="ConsultingPara"><span>Architecture and Design:</span> Our seasoned developers design scalable and efficient solutions for your software, prioritizing optimal performance and user experience.</p>
                            <p className="ConsultingPara"><span>Coding and Implementation:</span> Leveraging cutting-edge technologies, we implement your software solution using high-quality code and industry best practices.</p>
                            <p className="ConsultingPara"><span>Iterative Development:</span> We adopt an iterative development approach, providing regular updates and involving you in the process to align with your evolving requirements.</p>
                        </div>
                        {/* <div className="ConsultingImageDiv" data-aos="fade-right">
                            <img className="ConsultingImg" src={mb}></img>
                        </div> */}
                    </div>

                    <div className="Consulting-mainSection-subDiv" data-aos="fade-right">
                        {/* <div className="ConsultingImageDiv" data-aos="fade-right">
                            <img className="ConsultingImg" src={heath}></img>
                        </div> */}
                        <div className="ConsultingParaDiv" data-aos="fade-right">
                            <h1 className="SubDivHeading"> Quality Assurance and Testing</h1>
                            <p className="ConsultingPara"><span>Comprehensive Testing:</span> Our dedicated QA team conducts rigorous testing to identify and rectify any issues or bugs, guaranteeing a seamless user experience.</p>
                            <p className="ConsultingPara"><span>Performance Optimization:</span> We optimize your software for peak performance, scalability, and robust security measures to ensure reliability and efficiency.</p>
                            <p className="ConsultingPara"><span>User Acceptance Testing (UAT):</span> Before deployment, we conduct thorough UAT sessions to ensure your software meets your expectations and is user-friendly.
</p>
                        </div>
                    </div>

                    <div className="Consulting-mainSection-subDiv" data-aos="fade-right">
                        <div className="ConsultingParaDiv" data-aos="fade-right">
                            <h1 className="SubDivHeading">Deployment and Support</h1>
                            <p className="ConsultingPara"><span>Smooth Deployment:</span> We manage the deployment process meticulously, ensuring a seamless transition to the production environment without any disruption to your operations.</p>
                            <p className="ConsultingPara"><span>Ongoing Support:</span> Post-launch, our support team remains committed to providing continuous assistance, updates, and maintenance to keep your software running seamlessly.</p>
                            <p className="ConsultingPara"><span>Training and Documentation:</span> We conduct tailored training sessions and provide comprehensive documentation to empower your team in maximizing the benefits of your software solution.</p>
                        </div>
                        {/* <div className="ConsultingImageDiv" data-aos="fade-right">
                            <img className="ConsultingImg" src={softwearCon}></img>
                        </div> */}
                    </div>

                    
            </section>
      </div>  
  
  );
};

export default Consultancy;