import React, { useEffect,useRef } from "react";
import video1 from "./Images/video8.mp4";
import { Typewriter } from "react-simple-typewriter";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Header.css";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.css";


import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export const Header = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    // Access Swiper instance and add event listener for slide change
    const swiper = swiperRef.current.swiper;
    swiper.on("slideChange", handleSlideChange);

    // Cleanup function to remove event listener
    return () => {
      swiper.off("slideChange", handleSlideChange);
    };
  }, []);

  const handleSlideChange = () => {
    // Check if the second slide is active
    if (swiperRef.current.swiper.activeIndex === 1) {
      // Stop autoplay when the second slide is active
      swiperRef.current.swiper.autoplay.stop();
    }
  };

  return (
    <Swiper
      ref={swiperRef}
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 8000,
        disableOnInteraction: false,
      }}
      // pagination={{
      //   clickable: true,
      // }}
      // navigation={true}
       modules={[Autoplay]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="main-box">
          <div className="container-header">
            <h1>Welcome To</h1>
            <h3>The World Of 157</h3>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="main-box">
          <div className="container-header1">
            <h1>Recruitments <br />&</h1>
            <h3>IT Services</h3>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default Header;
