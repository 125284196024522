import React from 'react';
import './Consultancy.css';
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import Market from "../src/Images/Market&Research.jpg"
import mb from "../src/Images/MarB.jpg"
import heath from "../src/Images/Health.jpg"
import softwearCon from "../src/Images/SoftwearCon.jpg"

const Consultancy = () => {
      useEffect(() => {
    AOS.init({duration:2000})
  },[])
  return (
    // <div>
    // <section style={{width:"100%",maxWidth:"100%"}}>
    //   <div>
    //     <div className='ConsulatncymainDiv'data-aos="fade-right">
    //      <center data-aos="fade-right" ><h1 style={{color:'red',fontSize:"3rem",fontWeight:"bold"}}>CONSULTANCY SERVICES</h1></center>
    //       <div className='ConsulatncysubDiv'  data-aos="fade-right">
    //         <div className='ConsulatncyimgSection' data-aos="fade-right">
    //           <img className='ConsulatncyimgDiv'  src={Market} alt="" />
    //         </div>
    //         <div className='ConsulatncyparaDiv' data-aos="fade-right" >
    //           <h2 className='Consulatncyheading2'>Market Research & Analysis</h2>
    //           <p className='ConsulatncyParagraph1 justify'>
    //             Market research is essential for shaping your marketing strategy. When done effectively, it illuminates key aspects of your marketing efforts, like understanding your target audience's needs, aiding customer problem-solving, and refining your approach. 157 helps you assess the size of your new addressable market without costly and time-consuming efforts. With a proven track record in researching new markets, our solutions are customized for you."
    //           </p>
    //         </div>
    //       </div>

    //       <div className='ConsulatncysubDiv' data-aos="fade-right">
    //         <div className='ConsulatncyparaDiv' data-aos="fade-right">
    //           <h2 className='Consulatncyheading2'>Marketing and Business Development</h2>
    //           <p className="justify" >
    //             At 157 Industries, we specialize in delivering Digital Marketing and Commerce services, offering clients result-driven experiences. Our comprehensive approach involves in-depth analysis of current market strategies, trends, and customer data and insights, empowering organizations to confidently achieve new milestones.
    //           </p>
    //         </div>
    //         <div className='ConsulatncyimgSection' data-aos="fade-right">
    //           <img className='ConsulatncyimgDiv'  src={mb} alt="" />
    //         </div>
    //       </div>

    //       <div className='ConsulatncysubDiv' data-aos="fade-right">
    //         <div className='ConsulatncyimgSection' data-aos="fade-right">
    //           <img className='ConsulatncyimgDiv'  src={heath} alt="" />
    //         </div>
    //         <div className='ConsulatncyparaDiv' data-aos="fade-right">
    //           <h2 className='Consulatncyheading2'>Health Care Solutions</h2>
    //           <p className="justify" >
    //             At 157 Industries, our AI-driven Healthcare Solutions uplift hospital and clinic operations, introducing automated workflows and paperless processes for enhanced efficiency.Our solutions bridge gaps between patients, nurses, and doctors, streamlining hospital operations for a higher level of service quality. Empowering healthcare professionals to make quick decisions results in faster disease recovery. Our offerings include Telehealthcare, Hospital Management System, and Telemedicine Solutions.
    //           </p>
    //         </div>
    //       </div>

    //       <div className='ConsulatncysubDiv' data-aos="fade-right" style={{width:"100%",maxWidth:"100%"}}>
    //         <div className='ConsulatncyparaDiv' data-aos="fade-right">
    //           <h2 className='Consulatncyheading2'>Software Consulting & Sales</h2>
    //           <p className="justify" >
    //            In the dynamic landscape of business, software consulting services play a crucial role in evaluating and improving the efficiency of technology-enabled Business Process Management (BPM). Today, businesses across various industries thrive on new technologies, AI-based analytics, streamlined processes, and effective customer relationship management.At 157, we provide industry-specific software consulting solutions crafted to meet the unique business needs of sectors such as BFSI, NBFC, Healthcare, Telecom, Retail, Logistics, Hospitality, Media and Entertainment, and Corporates in India and Overseas.
    //           </p>
    //         </div>
    //         <div className='ConsulatncyimgSection' data-aos="fade-right">
    //           <img className='ConsulatncyimgDiv'   src={softwearCon} alt="" />
    //         </div>
    //       </div>

    //     </div>
    //   </div>
    // </section>
    // </div>


    <div>
            <section className="Consulting-mainSection">
                    <h1 className="ConsultingMainH1">Data Recovery</h1>
                    <div className="Consulting-mainSection-subDiv" data-aos="fade-right">
                        {/* <div className="ConsultingImageDiv" data-aos="fade-right">
                            <img className="ConsultingImg" src={Market} alt=''></img>
                        </div> */}
                        <div className="ConsultingParaDiv" data-aos="fade-right">
                            <h1 className="SubDivHeading">Assessment and Consultation:</h1>
                            <p className="ConsultingPara"><span>Description:</span> We conduct thorough evaluations of data loss scenarios and offer expert consultation on recovery options.
                            </p>
                            <p className="ConsultingPara"><span>Services:</span>
Comprehensive assessment of data loss situations
Identification of potential recovery methods
Consultation on best practices for data recovery
Recommendation of appropriate recovery solutions</p>
                           <p className="ConsultingPara">
                            <span>Benefits:</span>
Gain insights into data loss extent
Receive guidance on effective recovery approaches
Obtain clarity on restoration options
Benefit from expert advice for preventing future data loss.
                           </p>
                        </div> 
                    </div>

                    <div className="Consulting-mainSection-subDiv" data-aos="fade-right">
                        <div className="ConsultingParaDiv" data-aos="fade-right">
                            <h1 className="SubDivHeading">Data Retrieval and Restoration:</h1>
                            <p className="ConsultingPara"><span>Description:</span> Our advanced techniques ensure swift retrieval and restoration of lost or corrupted data.</p>
                            <p className="ConsultingPara"><span>Services:</span>
Retrieval from various storage media
Repair and reconstruction of damaged files
Restoration of deleted or formatted data
Extraction of inaccessible or overwritten data</p>
                            <p className="ConsultingPara"><span>Benefits:</span>
Swift recovery of critical business data
Effective restoration of lost files
Minimization of data loss impact
Secure preservation of valuable information assets.</p>
                        </div>
                        {/* <div className="ConsultingImageDiv" data-aos="fade-right">
                            <img className="ConsultingImg" src={mb}></img>
                        </div> */}
                    </div>

                    <div className="Consulting-mainSection-subDiv" data-aos="fade-right">
                        {/* <div className="ConsultingImageDiv" data-aos="fade-right">
                            <img className="ConsultingImg" src={heath}></img>
                        </div> */}
                        <div className="ConsultingParaDiv" data-aos="fade-right">
                            <h1 className="SubDivHeading"> Data Backup Solutions:</h1>
                            <p className="ConsultingPara"><span>Description:</span> We implement robust backup strategies for proactive data loss prevention and seamless recovery.
</p>
                            <p className="ConsultingPara"><span>Services:</span>
Tailored backup plan development
Automated backup system deployment
Regular data backup scheduling
Offsite and cloud backup solutions</p>
                            <p className="ConsultingPara"><span>Benefits:</span>
Proactively prevent future data loss incidents
Securely store backup copies for easy retrieval
Ensure quick recovery from disasters
Maintain compliance with data protection regulations.
</p>
                        </div>
                    </div>

                    <div className="Consulting-mainSection-subDiv" data-aos="fade-right">
                        <div className="ConsultingParaDiv" data-aos="fade-right">
                            <h1 className="SubDivHeading">Data Security and Prevention Measures:</h1>
                            <p className="ConsultingPara"><span>Description:</span> We implement proactive security measures to prevent data loss and corruption.</p>
                            <p className="ConsultingPara"><span>Services:</span>
Data encryption for enhanced security
Access controls and permissions implementation
Training on data protection best practices
Data loss prevention (DLP) solutions implementation</p>
                            <p className="ConsultingPara"><span>Benefits:</span>
Effective mitigation of data loss risks
Comprehensive protection of sensitive information
Seamless compliance with data privacy regulations
Significant reduction in vulnerabilities to cyber threats.</p>
                        </div>
                        {/* <div className="ConsultingImageDiv" data-aos="fade-right">
                            <img className="ConsultingImg" src={softwearCon}></img>
                        </div> */}
                    </div>

                    
            </section>
      </div>  
  
  );
};

export default Consultancy;