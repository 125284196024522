import React from 'react'

const ItSecurity = () => {
  return (
    <><div>
<section className="Consulting-mainSection">
        <h1 className="ConsultingMainH1">IT Security</h1>
        <div className="Consulting-mainSection-subDiv" data-aos="fade-left">
            {/* <div className="ConsultingImageDiv" data-aos="fade-left">
                <img className="ConsultingImg" src={digital} alt=''></img>
            </div> */}
            <div className="ConsultingParaDiv" data-aos="fade-left">
                <h1 className="SubDivHeading">Network Security:</h1>
                <p className="ConsultingPara"><span>Description:</span> Protects data integrity, confidentiality, and availability across networks.</p>
                <p className="ConsultingPara"><span>Services:</span>
                 Firewall setup and management
                 Intrusion Detection and Prevention
                 Virtual Private Network (VPN)
                 Network segmentation</p>
                <p className="ConsultingPara"><span>Benefits:</span>
                Safeguards sensitive data
                Prevents network disruptions
                Ensures compliance
                Maintains business continuity</p>

            </div>
        </div>

        <div className="Consulting-mainSection-subDiv" data-aos="fade-left">    
            <div className="ConsultingParaDiv" data-aos="fade-left">
                <h1 className="SubDivHeading">Endpoint Security:</h1>
                <p className="ConsultingPara"><span>Description:</span> Secures individual devices from cyber threats.
                </p>
                <p className="ConsultingPara"><span>Services:</span>
Antivirus and anti-malware
Endpoint detection and response (EDR)
Device encryption
Mobile device management (MDM)
                </p>
                <p className="ConsultingPara"><span>Benefits:</span>
Protects against malware and data loss
Ensures regulatory compliance
Enables secure remote work
                </p>
                
            </div>
            {/* <div className="ConsultingImageDiv" data-aos="fade-left">
                <img className="ConsultingImg" src={customerOnBoarding}></img>
            </div> */}
        </div>

        <div className="Consulting-mainSection-subDiv" data-aos="fade-left">
            {/* <div className="ConsultingImageDiv" data-aos="fade-left">
                <img className="ConsultingImg" src={voice}></img>
            </div> */}
            <div className="ConsultingParaDiv" data-aos="fade-left">
                <h1 className="SubDivHeading">Data Security:</h1>
                <p className="ConsultingPara"><span>Description:</span> Protects sensitive information from unauthorized access.</p>
                <p className="ConsultingPara"><span>Services:</span>
Data encryption
Access control measures
Data loss prevention (DLP)
Regular data backups</p>
                <p className="ConsultingPara"><span>Benefits:</span>
Mitigates data breaches
Protects customer information
Builds trust with stakeholders</p>
            </div>
        </div>

        
        <div className="Consulting-mainSection-subDiv" data-aos="fade-left">
            {/* <div className="ConsultingImageDiv" data-aos="fade-left">
                <img className="ConsultingImg" src={digital} alt=''></img>
            </div> */}
            <div className="ConsultingParaDiv" data-aos="fade-left">
                <h1 className="SubDivHeading">Security Awareness Training:</h1>
                <p className="ConsultingPara"><span>Description: </span>Educates employees about cybersecurity best practices.</p>
                <p className="ConsultingPara">
                   <span> Services:</span>
Customized training programs
Phishing simulations
Regular security updates
Monitoring and reporting

                </p>
                <p className="ConsultingPara">
                   <span>Benefits:</span>
Empowers employees to identify threats
Reduces human error
Fosters a culture of security awareness
                </p>
            </div>
        </div>

        {/* <div className="Consulting-mainSection-subDiv" data-aos="fade-left">
            <div className="ConsultingParaDiv" data-aos="fade-left">
                <h1 className="SubDivHeading">Data Management</h1>
                <p className="ConsultingPara">157 provides comprehensive data management services, securely storing data following client guidelines. Regular data management and audits are conducted based on client preferences. Data analysis is visually presented using graphs and charts with different categories, enhancing clarity on trends. This visual approach surpasses traditional tables, aiding informed decision-making.</p>
            </div>
            <div className="ConsultingImageDiv" data-aos="fade-left">
                <img className="ConsultingImg" src={customerOnBoarding}></img>
            </div>
        </div> */}

        
</section>
</div>  
</>
  )
}

export default ItSecurity