import React from 'react'
import './About1.css'
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css"
import aboutUs from "../src/Images/aboutUs.jpg";
import weHire from "../src/Images/weHire.jpg"
import { Link } from 'react-router-dom';
import Health from "./Images/healthCare.jpg";
import Banking from "./Images/Banking.jpg";
import Oem from "./Images/Oem.jpg"

function OverView() {
        useEffect(() => {
    AOS.init({duration:2000})
  },[])
  return (
    <div> 
    <div className="OvermainSection" data-aos="zoom-in">
        <div className="OveraboutMainDiv" data-aos="zoom-in">
            {/* <div className="OverimageDiv" style={{padding:"40px"}} data-aos="zoom-in">
                <img className="Overimagee" src={aboutUs} alt='About Us Image'/>
            </div> */}
            <div className="OverinfoDiv" data-aos="zoom-in">
                <h1 className="Overabouth1" data-aos="zoom-in">ABOUT US</h1>
                <h1 className="OveraboutHead" data-aos="zoom-in">Dedicated to value, dedicated to you</h1>
                <p className="Overaboutpara" data-aos="zoom-in">157 Industries Business Ventures Pvt Ltd was founded by entrepreneurs Ajinkya Bandamantri and Ashok Murdikar. We are committed to contributing to women's empowerment and creating job opportunities for the differently-abled through technology enablement. With over 6 years of operational experience, IBVL provides consulting, outsourcing, and technology services, covering IT, ITES, back-office/credit processing, BPM, and digital transformation. Holding ISO 9001:2015 and ISO/IEC 27001:2013 certifications, along with MSME and NSIC registrations, we serve leading industries in BFSI, NBFC, healthcare, telecom, retail, hospitality, media & entertainment, and corporates in India and overseas.Our team, with over 6 years of multi-domain industry experience, operates in six metro cities in India - Mumbai, Pune, Chennai, Bangalore, Hyderabad, and Noida - with a human capital of over 650, delivering premium services to our esteemed customers.At 157 Industries, our entrepreneurial, engineering, and research teams collaborate to explore opportunities, challenges, and solutions in artificial intelligence, machine learning, and big data analytics. We offer innovative business transformation solutions and digital BPM services for customer </p>
            </div>
        </div>
        <div> 
    <section className="OvermainSection" data-aos="zoom-in">
        <div className="OveraboutMainDiv" data-aos="zoom-in"> 
            <div className="OverinfoDiv" data-aos="zoom-out">
                
                {/* <div className="OverimageDiv" data-aos="zoom-in">
                <img className="Overimagee" src={weHire} alt='About Us Image'/>
            </div> */}
            <hr />
                <h1 className="OveraboutHead" data-aos="zoom-in">Why 157?</h1>
                <hr />
                <p className="Overaboutpara" data-aos="zoom-in">From the inception of introducing - innovative end user products, we were hooked on having a unique name to associate our products with. Instead of jumping on the bandwagon of entirely lifting of an eminent brandName, a clichéd adjective or an alpha numeric blend, we opted to go all numeric 157 - which    we believe is simple,, easy to resonate with any individual from every age group and will be devoid of any race, religion, region and language biases.157 in itself is a “Symbol of Uniqueness” which also is the tagline of our company. Also 157 gives us the edge to conducively market and promote our products on global platform. </p> 
                <hr />
                <h1 className="OveraboutHead" data-aos="zoom-in">Our story </h1>
                <hr />
                <p className="Overaboutpara" data-aos="zoom-in">When we thought of starting an innovating product sale company we thought, rather considering any already popular name/word or any dictionary word or synonymous and giving name to our company decided to choose random number and will give new meaning to it. As “Symbol of uniqueness” it's our slogan. So we are promoting our company name (number), logo as symbol of uniqueness. So the company name is NUMBER “157” which cannot be classified by any region, religion, language which will give us vast scope of publicity worldwide.</p>
                <hr />
                
                <hr />
                <h1 className="OveraboutHead" data-aos="zoom-in">What is the Logo is about?</h1>
                <hr />
                <p className="Overaboutpara" data-aos="zoom-in">Its abstract design </p>  
                <hr /> 
                 <h1 className="OveraboutHead" data-aos="zoom-in">What is the vision of 157?</h1>
                 <hr />
                <p className="Overaboutpara" data-aos="zoom-in">Vision of our organization is to increase the satisfaction level of our customers.</p>
                <hr />
                <h1 className="OveraboutHead" data-aos="zoom-in">What is the mission of 157?</h1>
                <hr />
                <p className="Overaboutpara" data-aos="zoom-in">To become paradigm of the industry</p>   
                <hr />
                
            </div>
        </div>
        
        <div className="OverVMmainDiv" data-aos="zoom-in"> 
            <div className="OvervmSubDiv" data-aos="zoom-in">
                <div className="OvervmHeadDiv" data-aos="zoom-in">
                    <h1 className="OvervmHead" data-aos="zoom-in">Our Vision</h1>
                </div>
                <div className="OvervmInfoDiv" data-aos="zoom-in">
                    <h3 className="OvervmH" data-aos="zoom-in">Pinnacle of Service Excellence</h3>
                    <p className="OvervmPara" data-aos="zoom-in">We aspire to stand as a globally renowned back office service provider, distinguished for our unwavering commitment to delivering tangible and reliable value to businesses.</p>
                    <h3 className="OvervmH" data-aos="zoom-in">Strategic Partnership Excellence</h3>
                    <p className="OvervmPara" data-aos="zoom-in">Our ambition is to achieve the coveted status of a "Preferred Strategic Solutions Partner" for global Fortune 500 companies, ensuring a sustained delivery of high-quality outputs recognized by leading global research and advisory firms. Our ultimate objective is to secure the foremost position in the outsourcing and business consulting industry.</p>
                </div>
            </div>
            <div className="OvervmSubDiv" data-aos="zoom-in">
                <div className="OvervmHeadDiv" data-aos="zoom-in">
                    <h1 className="OvervmHead" data-aos="zoom-in">Our Mission</h1>
                </div>
                <div className="OvervmInfoDiv" data-aos="zoom-in">
                    <h3 className="OvervmH" data-aos="zoom-in">Global Excellence</h3>
                    <p className="OvervmPara" data-aos="zoom-in">Strive for a worldwide presence by delivering unparalleled IT-enabled services to international companies. We prioritize swift, cost-effective solutions, ensuring unwavering customer satisfaction.</p>
                    <h3 className="OvervmH" data-aos="zoom-in">Innovative Pursuits</h3>
                    <p className="OvervmPara">Devote relentless efforts to achieving our goals by cultivating a platform that not only inspires but also supports innovative business solutions.</p>
                </div>
            </div>
        </div>

<div className='Industries-main-div'>
            <center><h1 className='industries-we-serve-heading'>INDUSTRIES WE SERVE</h1></center>
           
            <div className="industries-we-serve-boxMainDiv1" data-aos="zoom-in">
     
                <div className="industries-we-serve-boxMainDiv-subDiv" >
                    <img src={Banking} alt="Black and White Image"/>
                    <div className="image-name">BFSI-Banking, Financial Services and Insurance</div>
                </div>
                <div className="industries-we-serve-boxMainDiv-subDiv" >
                    <img src="https://media.istockphoto.com/id/1249219777/photo/shopping-online-concept-parcel-or-paper-cartons-with-a-shopping-cart-logo-in-a-trolley-on-a.jpg?s=1024x1024&w=is&k=20&c=Gsr6lZkBHjjeP5o18w9_mvnWxMZBqB-ncOi6tqh87hM=" alt="Black and White Image"/>
                    <div className="image-name">Retails & E Commerse</div>
                </div>
                <div className="industries-we-serve-boxMainDiv-subDiv" >
                    <img src={Oem}/>
                    <div className="image-name">Original Equipment Manufacturer (OEM)</div>
                </div>
                <div className="industries-we-serve-boxMainDiv-subDiv" id='industries-we-serve-boxMainDiv-subDiv4' >
                    <img src={Health} alt="Black and White Image"/>
                    <div className="image-name">Health Care</div>
                </div>
                <div className="industries-we-serve-boxMainDiv-subDiv" >
                    <img src="https://cdn.pixabay.com/photo/2014/10/14/20/14/library-488690_640.jpg" alt="Black and White Image"/>
                    <div className="image-name">Education</div>
                </div>
            </div>
            <div className="industries-we-serve-boxMainDiv2" data-aos="zoom-in">
                
            </div>
    </div>
    <div className="industries-we-serve-info-section" data-aos="zoom-in">
        <h1 className="industries-we-serve-info-section-heading" data-aos="zoom-in">BFSI-Banking, Financial Services and Insurance</h1>
        <hr className="hrr"/>
        <p className="industries-we-serve-info-section-description" data-aos="zoom-in">
            The realm of cashless payments is experiencing rapid expansion, driven by evolving payment methods, increased e-commerce utilization, improved broadband connectivity, and the integration of new technologies. <br /> In today's dynamic digital landscape, where efficiency and speed are paramount, the majority of customer transactions occur online. <br /> Cashless payment methods, including innovations like mobile wallets, peer-to-peer (P2P) mobile payments, real-time payments, and cryptocurrencies, are swiftly evolving.In this era of digital advancement, 157 Industries leverages profound knowledge and a suite of exceptional products and services tailored specifically for the Banking sector. With the growing adoption of technologies such as Virtual Reality, Artificial Intelligence, and rapid Digitization, a significant number of individuals are embracing contactless payments. <br />157 Industries actively contributes to the digital transformation and online business processes of leading banks and NBFCs in India, including IndusInd Bank Ltd, IDFC First Bank Ltd, Fedbank Financial Services Ltd. Our cost-efficient and effective products and services play a pivotal role in this transformative journey.
        </p>
        <br/>
        <h1 className="industries-we-serve-info-section-heading" data-aos="zoom-in">Retail & E-Commerce</h1>
        <hr className="hrr"/>
        <p className="industries-we-serve-info-section-description" data-aos="zoom-in">
            Online retailing acts as a digital link connecting retailers and consumers, facilitating the sale of products and services through e-commerce platforms. The popularity of online retail and e-commerce among retailers is fueled by advantages like low investment costs, direct access to target customers, and rapid returns on investment. <br /> This retail format enables retailers to efficiently serve customers by offering a diverse range of products and services. <br />157 Industries uses its technological and retail expertise to enhance the customer shopping experience through advanced technology in design, analytics, system integration, product engineering, and artificial intelligence. <br /> We provide innovative e-commerce platforms introducing buyers and sellers to new technology, advanced features, and a user-friendly transaction flow. <br />Moreover, 157 Ventures empowers buyers and sellers to streamline operations with our advanced technologies, improving competitive stances and retail business operations.    
        </p>
        <br/>
        <h1 className="industries-we-serve-info-section-heading" data-aos="zoom-in">OEM</h1>
        <hr className="hrr"/>
        <p className="industries-we-serve-info-section-description" data-aos="zoom-in">
            At 157 Industries, we operate as an Original Equipment Manufacturer (OEM), producing components or products that are acquired by other companies and seamlessly integrated into their final products. The purchasing company, referred to as the 'original equipment manufacturer,' subsequently markets and sells the end product under its own brand name. <br />  Our OEM expertise extends across diverse industries, such as electronics, automotive, and machinery, where we supply specialized components or subsystems that play a crucial role in enhancing the functionality of the final product.
        </p>
        <br/>
        <h1 className="industries-we-serve-info-section-heading" data-aos="zoom-in">Health Care</h1>
        <hr className="hrr"/>
        <p className="industries-we-serve-info-section-description" data-aos="zoom-in">
            157 Industries empowers clients to implement and manage digital healthcare solutions, maintaining comprehensive case management records and guiding them on their journey toward digital transformation. <br />Our healthcare solutions cover the entire healthcare cycle, including doctor's appointment scheduling, self-check-in, remote consultations, data ingestion, medical aid, testing requests, patient enrollment, tab-based centralized patient statistics management, case management, expense management, account settlement, billing, patient discharge, claims management, record auditing, and workflow management. <br />In response to the challenges faced by doctors and patients, and the increasing demand for Virtual Healthcare, 157 Industries introduces SmartConsult, a Digital Healthcare solution. <br />Utilizing technology such as telephones, video, mobile apps, text-based messaging, and other communication platforms, SmartConsult delivers health services to patients outside the traditional healthcare setting. <br />Through SmartConsult, doctors can optimize their time with faster interactions, ensuring the safety of their patients' lives. This practice is especially valuable for treating patients in remote locations, far from local health facilities or areas with a shortage of medical professionals. <br /> SmartConsult effectively bridges the gap between people and healthcare systems globally, enhancing the efficiency of care delivery and reducing expenses associated with transporting patients to hospitals.         
        </p>
        <br/>
        <h1 className="industries-we-serve-info-section-heading" data-aos="zoom-in">Education Management</h1>
        <hr className="hrr"/>
        <p className="industries-we-serve-info-section-description" data-aos="zoom-in">
           E-learning, or virtual education, is the widely used term for applying modern electronic technology to teaching and learning. Initially used for distance learning, it has become a powerful tool for interactive virtual education.Today, web-based learning is a global phenomenon known for its flexibility, particularly beneficial for those with limited financial resources, offering opportunities for higher education and academic completion.Institutions of all sizes are actively moving away from traditional methods, embracing a technology-centric approach to education. This shift aims to simplify educational processes and strengthen connections among students, teachers, parents, and management.
        </p>
    </div>
        <div className="OverreachUSDiv">
            <div className="OverreachUsInfoDiv" data-aos="zoom-in">
                <h1 className="OverreachUsInfo" data-aos="zoom-in">We wield dynamic business transformation capabilities and embody a consultative approach grounded in extensive domain expertise.</h1>
            </div>
            <div className="OverreachUsBtnDiv" data-aos="zoom-in">
                <Link to="/contactus"><button className="Overbtn">Reach Us  <i className="fa-solid fa-arrow-right"></i></button></Link>
            </div>
        </div>
    </section>
    </div>
    </div>
    </div>
  )
}

export default OverView